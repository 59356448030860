import { render, staticRenderFns } from "./carTypeTable.vue?vue&type=template&id=28d9e778&scoped=true"
import script from "./carTypeTable.vue?vue&type=script&lang=js"
export * from "./carTypeTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28d9e778",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/codingci/tools/jenkins_home/workspace/1416652-cci-50406239-448444/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('28d9e778')) {
      api.createRecord('28d9e778', component.options)
    } else {
      api.reload('28d9e778', component.options)
    }
    module.hot.accept("./carTypeTable.vue?vue&type=template&id=28d9e778&scoped=true", function () {
      api.rerender('28d9e778', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/projectTravel/basicDataAdmin/component/carTypeTable.vue"
export default component.exports