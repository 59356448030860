var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "carTypeTable" },
    [
      _c("Table", {
        ref: "Table",
        attrs: {
          "type-checkbox": true,
          "table-data": _vm.tableData,
          "title-name": _vm.titleName,
          "col-class-type": true,
          "table-height": 380,
          onOff: false,
        },
        on: { "select-all": _vm.selectAll },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }