<template>
  <!-- 车型分类表格 -->
  <div class="carTypeTable">
    <Table
      ref="Table"
      :type-checkbox="true"
      :table-data="tableData"
      :title-name="titleName"
      :col-class-type="true"
      :table-height="380"
      :onOff="false"
      @select-all="selectAll"
    ></Table>
  </div>
</template>

<script>
import { renderCarTypeListAPI2 } from "@/api/lib/api.js";
export default {
  props: {
    propData: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      tableData: [],
      titleName: [
        {
          title: "车型分类名称",
          props: "vehicleTypeName",
          width: "160",
        },
        {
          title: "子类",
          props: "vehicleTypeChildName",
        },
        {
          title: "分类描述",
          props: "modelTypeDescription",
        },
        {
          title: "座位数",
          props: "seatNum",
        },
        {
          title: "行李数",
          props: "luggageNum",
        },
        {
          title: "图片",
          props: "picture",
          render: (h, params, index) => {
            return h("i", {
              class: "iconfont its_gy_image",
              style: {
                "font-size": "18px",
                cursor: "pointer",
                color: "#336FFE",
              },
              on: {
                click: (e) => {
                  e.stopPropagation();
                  this.$emit("imgSee", params.picture);
                },
              },
            });
          },
        },
      ],
    };
  },
  mounted() {
    this.renderData().then((res) => {
      console.log(this.tableData);
      console.log(this.Data);
      this.tableData.map((item, index) => {
        console.log(item);
        this.Data.map((jump) => {
          if (jump.vehicleModelTypeId == item.id) {
            console.log(index);
            this.$refs.Table.$refs["Table"].toggleRowSelection(item, true);
          }
        });
      });
    });
    console.log(this.Data);
    console.log(this.$refs.Table);
    console.log(this.tableData);
  },
  methods: {
    selectAll(check) {
      this.$emit("selectData", check);
    },
    renderData() {
      return new Promise((resolve) => {
        renderCarTypeListAPI2().then((res) => {
          if (res.code == "SUCCESS") {
            this.tableData = res.data;
            resolve();
          }
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>